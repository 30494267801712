import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Smooth scroll to section
  /*const handleScroll = (e, sectionId) => {
    e.preventDefault(); // Prevent the default anchor click behavior
    const section = document.querySelector(sectionId); // Get the section by ID
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth', // Enable smooth scrolling
        block: 'start', // Scroll to the start of the section
      });
    }
    setMenuOpen(false); // Close the mobile menu after clicking
  };*/

  return (
    <nav className="navbar">
      <div className="logo">DawkinsDigital</div>
      <div className={`nav-links ${menuOpen ? 'navbar-links-mobile' : ''}`}>
      <Link
          to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>HOME
        </Link>

        <Link
          to="/#about"
          smooth={true}
          duration={500}
          onClick={toggleMenu}
        >
          ABOUT
        </Link>

        <Link
          to="/#newsletter"
          smooth={true}
          duration={500}
          onClick={toggleMenu}
        >
          NEWSLETTER
        </Link>

        <Link
          to="/#contact"
          smooth={true}
          duration={500}
          onClick={toggleMenu}
        >
          CONTACT
        </Link>
      </div>
      <div className="navbar-menu" onClick={toggleMenu}>
        <div className="navbar-menu-lines"></div>
        <div className="navbar-menu-lines"></div>
        <div className="navbar-menu-lines"></div>
      </div>
    </nav>
  );

};

export default Navbar;
