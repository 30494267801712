import React, { useEffect, useState } from 'react';
import '../App.css';
import { marked } from 'marked';  // If you want to parse markdown (not needed for plain text)
import heroContent from '../content/hero/hero.md';
import parse from 'html-react-parser';

const Hero = () => {
  const [content, setContent] = useState({ title: '', text: '' });

  useEffect(() => {
    // Fetch the hero content and parse it
    fetch(heroContent)
      .then((res) => res.text())
      .then((text) => {
        const parsedContent = {
          title: 'Hi I\'m Stephen',  // Extract this from the markdown or content file if needed
          text: marked(text),  // You can extract this as well
        };
        setContent(parsedContent);
      });
  }, []);

  const scrollToContact = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section id="hero" className="hero">
      <div className="hero-overlay">
        <div className="hero-title">
          <h1>{content.title}</h1>
        </div>
        <div className="hero-description">
          <p>{parse(content.text)}</p>
        </div>
        <div className="hero-button">
          <button onClick={scrollToContact}>Contact me now</button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
