import React, { useState } from 'react';
//import emailjs from 'emailjs-com';
import '../App.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email) errors.email = 'Email is required';
    if (!formData.message) errors.message = 'Message is required';
    /*if (!captchaVerified) errors.captcha = 'Please complete the CAPTCHA';*/
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);

      try {
        const response = await fetch('https://cd4r750q8l.execute-api.us-east-1.amazonaws.com/test/send-email', {  // Use your API Gateway URL here
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            message: formData.message
          })
        });

        if (response.ok) {
          setIsSubmitted(true);
        } else {
          throw new Error('Failed to send email');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div id="contact" className="contact">
      <h2 className="section-title">Contact</h2>
      {isSubmitted ? (
        <p>Thank you for submitting your inquiry!</p>
      ) :  (
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="input-row">
            <input 
              type="text" 
              name="name" 
              placeholder="Your Name" 
              className="input-field"
              onChange={handleChange} 
              required
            />
            <input 
              type="email" 
              name="email" 
              placeholder="Your Email" 
              className="input-field"
              onChange={handleChange} 
              required
            />
          </div>
          <textarea 
            name="message" 
            placeholder="Your Message" 
            className="input-field message-field" 
            maxLength="1000"
            onChange={handleChange} 
            required
          />
          <button type="submit" className="submit-button" disabled={isLoading}>
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>

          {isLoading && <div className="loading-spinner"></div>}
        </form>
      )}
    </div>
  );
};

export default Contact;
