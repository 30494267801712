import React from 'react';
import '../App.css';
import { Link as RouterLink } from 'react-router-dom';

//import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
  <p>© 2024 Dawkins Digital Solutions B.V. - John M. Keynesplein 1 1066 EP Amsterdam</p>
  <div className="social-icons">
    <a href="https://www.instagram.com/iguessimstephan/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
      <i className="fab fa-instagram"></i>
    </a>
    <a href="https://www.linkedin.com/in/stephen-dawkins-4ab82078/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
      <i className="fab fa-linkedin"></i>
    </a>
    <a href="https://github.com/smdawkins" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
      <i className="fab fa-github"></i>
    </a>
    <RouterLink className="policies" to="/privacy-policy" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} >
          Privacy Policy
        </RouterLink>
    <RouterLink className="policies" to="/terms-and-service" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} >
          Terms and Service
        </RouterLink>
  </div>
</footer>

  );
};

export default Footer;
