import React,{ useEffect, useRef, useState } from 'react';
import '../App.css';
import profileImage from '../assets/stephen_head.jpg';
import aboutContent from '../content/about/about.md';
import {marked} from 'marked'; 
import parse from 'html-react-parser';

const About = () => {
  const [content, setContent] = useState({ title: '', body: '', image: '' });

  useEffect(() => {
    // Load the markdown content and parse it
    fetch(aboutContent)
      .then((res) => res.text())
      .then((text) => {
        // Here we're assuming your markdown file contains some YAML frontmatter (e.g., title, image, body)
        const parsedContent = {
          title: 'About',  // You can manually extract this from markdown or YAML frontmatter if you are using it
          body: marked(text), // Convert markdown to HTML using marked
          image: profileImage           // Add an image URL if needed
        };
        setContent(parsedContent);
      });
  }, []);

  const aboutRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = aboutRef.current;
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const screenPosition = window.innerHeight / 1.3;
  
        if (elementPosition < screenPosition) {
          element.classList.add('fade-in');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="about" className="about" ref={aboutRef}>
      <div className="about-container">
        <div className="image-and-title-container">
          <h2 className="section-title">{content.title}</h2>
          <div className="about-image">
            {content.image && <img src={content.image} alt="About" />}
          </div>
        </div>
        <div className="about-text">{parse(content.body)} </div>
      </div>
    </div>
  );
};

export default About;
