import React from 'react';
import '../App.css'; // Assuming you use a separate CSS file for styling

const Newsletter = () => {
  return (
    <section id="newsletter" className="newsletter-section">
      <div className="newsletter-container">
        <h2 className="section-title">Newsletter</h2>
        <h3 className="coming-soon-text">Coming Soon</h3>
      </div>
    </section>
  );
};

export default Newsletter;
