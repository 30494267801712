import React,  { useEffect } from 'react';
import Hero from '../components/Hero';  // Adjust path based on your structure
import About from '../components/About';
import Newsletter from '../components/Newsletter';
import Contact from '../components/Contact';

import { useLocation } from 'react-router-dom';

const Home = () => {
    const location = useLocation();

    useEffect(() => {
      // Check if there is a hash in the URL (e.g., #about, #contact)
      if (location.hash) {
        const section = document.querySelector(location.hash);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  return (
    <div>
      <Hero />
      <About />
      <Newsletter />
      <Contact />
    </div>
  );
};

export default Home;
