import React, { useState, useEffect } from 'react';
import { marked } from 'marked';  // If you need markdown support
import parse from 'html-react-parser';  // To safely render HTML
import privacyPolicyContent from '../content/terms-service/terms-service.md';  // Path to the markdown file

const TermsAndService = () => {
  const [content, setContent] = useState({ title: 'Terms and Service', body: '' });

  useEffect(() => {
    // Fetch the markdown content
    fetch(privacyPolicyContent)
      .then((res) => res.text())
      .then((text) => {
        const parsedContent = {
          title: 'Terms and Service',  // Default title from the CMS
          body: marked(text),  // Convert markdown to HTML
        };
        setContent(parsedContent);
      });
  }, []);

  return (
    <div style={{ backgroundColor: '#EBF5FF', padding: '50px', minHeight: '100vh' }}>
      <div className="policy-container" style={{ color: '#363B69' }}>
        <h1>{content.title}</h1>
        <div>{parse(content.body)}</div>
      </div>
    </div>
  );
};

export default TermsAndService;
